<script setup lang="ts">
const props = withDefaults(defineProps<Omit<TabbarProps, 'modelValue'>>(), {
  fixed: true,
  route: true,
  placeholder: true,
  border: false,
  activeColor: '#ffffff',
  inactiveColor: 'rgba(255, 255, 255, 0.6)',
})
const localPath = useLocalePath()
const { t } = useI18n()
const cartStore = useCartStore()

interface TabbarProps {
  fixed?: boolean
  route?: boolean
  placeholder?: boolean
  border?: boolean
  activeColor?: string
  inactiveColor?: string
  beforeChange?: (to: number | string) => boolean | Promise<boolean>
}

function beforeChange() {
  return !isInsideIframe
}

const active = ref('home')
</script>

<template>
  <van-tabbar
    v-model="active"
    class="custom-tabbar"
    v-bind="{ ...props }"
    :before-change="beforeChange"
  >
    <van-tabbar-item
      name="home"
      :to="localPath('/')"
    >
      <template #icon>
        <svgo-homes />
      </template>
      {{ t("tabbar.home") }}
    </van-tabbar-item>
    <van-tabbar-item
      name="recipe"
      :to="localPath('/recipe')"
    >
      <template #icon>
        <svgo-shipu />
      </template>
      {{ t("tabbar.recipe") }}
    </van-tabbar-item>
    <van-tabbar-item
      name="qrcode"
      class="custom-tabbar-item"
      :to="localPath('/member')"
    >
      <template #icon>
        <div class="round">
          <div class="icon">
            <svgo-qrcode filled />
          </div>
        </div>
      </template>
      {{ t("tabbar.qrcode") }}
    </van-tabbar-item>
    <van-tabbar-item
      name="cart"
      :to="localPath('/cart')"
      :badge="cartStore.total"
      :badge-props="{ max: 99, showZero: false }"
    >
      <template #icon>
        <svgo-carts />
      </template>
      {{ t("tabbar.cart") }}
    </van-tabbar-item>
    <van-tabbar-item
      name="user"
      :to="localPath('/user')"
    >
      <template #icon>
        <svgo-account />
      </template>
      {{ t("tabbar.user") }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<style scoped lang="scss">
:deep(.van-tabbar) {
  z-index: 101;
}
.custom-tabbar-item {
  .round {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #743a3a;
    padding: 6px;
    margin-top: -30px;
    .icon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #a68181;
      border-radius: 50%;
      // color: #743a3a;
    }
  }
  &.van-tabbar-item--active {
    .icon {
      background-color: #ffffff;
    }
  }
}
</style>
